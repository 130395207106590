export const documentTypes = {
  BHR_ID: "Bahrain ID",
  GENERIC_ID: "National ID",
  KWT_ID: "Kuwait ID",
  OMN_ID: "Oman ID",
  PAK_ID: "Pakistan ID",
  PASSPORT: "Passport",
  SAU_ID: "Saudi ID",
  UAE_ID: "Emirates ID",
  UAE_DL: "UAE Driving License",
  NLD_DL: "NLD Driving License",
  UAE_VISA: "UAE Resident Visa",
  UAE_VL: "UAE Vehicle License",
  QAT_ID: "Qatar ID",
  DEU_ID: "German ID",
  SDN_ID: "Sudan ID",
  SDN_DL: "SDN Driving License",
  SDN_VL: "SDN Vehicle License",
  GHA_ID: "Ghana ID",
  NGA_DL: "NGA Driving License",
  NGA_VOTER_ID: "NGA Voter ID",
  NGA_NIN: "NGA NIN",
  UAE_VL_PDF: "UAE Vehicle License (Electronic Version)",
  GBR_DL: "GBR Driving License",
  SAU_DL: "SAU Driving License",
  ZAF_ID: "South African ID",
  ZAF_DL: "ZAF Driving License",
  EGY_ID: "Egyptian ID",
  RWA_ID: "Rwandan ID",
  KEN_ID: "Kenyan ID",
  GHA_DL: "GHA Driving License",
  GHA_VOTER_ID: "GHA Voter ID",
  GHA_SSNIT: "GHA SSNIT",
  UGA_ID: "Ugandan ID",
  IND_ID: "Indian Aadhaar ID",
  UGA_VOTER_ID: "UGA Voter ID",
  SEN_ID: "Senegal ID",
  GBR_ID: "UK Resident ID",
  COD_DL: "DRC Driving License",
  DZA_ID: "Algeria ID",
  TUR_ID: "Turkish ID",
  COD_VOTER_ID: "Voter ID",
  OMN_VL: "Vehicle License",
  OMN_DL: "Driving License",
  USA_DL: "Driving License",
  QAT_DL: "Driving License",
  BHR_DL: "Driving License",
  PHL_DL: "Driving License",
  RSL_ID: "National ID",
  IND_PAN: "PAN card",
  LBN_ID: "Lebanese ID",
  LBN_DL: "Lebanese Driving License",
  IRQ_ID: "Iraqi ID",
  MRZ: "MRZ",
  UAE_ID_DIGITAL: "Digital Emirates ID",
  QAT_VL: "Qatar Vehicle License",
  UAE_PASSPORT_DIGITAL: "Digital Emirates Passport",
  TUN_ID: "Tunisian ID",
};

export type TDocumentType = keyof typeof documentTypes;

export const licensesTypes = {
  IN_USE: "In Use",
  USED: "Consumed",
  NOT_USED: "Purchased",
};

export const authCodes = {
  user_forbidden:
    "User is not authorized to access the portal. Try to sign-in with a different user",
  auth_failed: "Please sign in to continue. Click the button below to proceed",
  logout: "User logged out successfully",
  session_expired: "Your session is expired please login again",
};

export type IAuthCodes = keyof typeof authCodes;
